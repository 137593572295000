<template>
  <div class="title mb-[20px] pc:hidden">{{ $t('space_type_list.mobile_title') }}</div>
  <div class="space-type-list-wrap">
    <div class="title-wrap">
      <svg-icon name="manage_search"
                size="36"></svg-icon>
      {{ $t('space_type_list.title') }}
    </div>
    <div class="divider mobile:hidden"></div>
    <div class="type-wrap">
      <swiper class="w-full"
              :slides-per-view="'auto'"
              :centeredSlides="false"
              :space-between="isMobile ? 8 : 4"
              @swiper="onSwiper"
              :pagination="false"
              :modules="[Pagination]">
        <swiper-slide v-for="type in spaceTypeList">
          <div @click="handleTypeClick(type)"
               class="type-card-wrap">
            <svg-icon :name="getCategoryImageFileName(type.id)"
                      :size="isMobile ? '30' : '44'"></svg-icon>
            <span>{{ type.name }}</span>
          </div>
        </swiper-slide>
      </swiper>
    </div>

    <div class="space-type-prev" @click="handleScroll(-130)">
      <svg-icon name="space_type_prev"
                size="50"></svg-icon>
    </div>
    <div class="space-type-next" @click="handleScroll(130)">
      <svg-icon name="space_type_next"
                size="50"></svg-icon>
    </div>
  </div>
</template>

<script setup>
import 'swiper/css';
import 'swiper/css/pagination';
import {computed, nextTick, ref} from "vue";
import {useCategoryStore} from "@/stores/category.js";
import {useSearchQueryStore} from "@/stores/searchQuery.js";
import {useGoTo} from "@/composables/useGoTo";
import {useSearchWithStore} from "@/composables/useSearchWithStore";
import {Swiper, SwiperSlide} from "swiper/vue";
import {Pagination} from 'swiper/modules';
import {useSettingStore} from "@/stores/setting.js";
import {getCategoryImageFileName} from "@/utils/parseTool.js";

const settingStore = useSettingStore()
const {handleSearchWithStore} = useSearchWithStore()
const {go} = useGoTo();
const typeCategoryId = import.meta.env.VITE_APP_BASE_TYPE_CATEGORY_ID.toString()

const searchQueryStore = useSearchQueryStore()
const categoryStore = useCategoryStore()
const spaceTypeList = computed(() => categoryStore.getSpaceTypeCategories)

const refSwiper = ref(null)

// const isMobile = computed(() => settingStore.isMobile);
const {isMobile} = useDevice();

function onSwiper(swiper) {
  refSwiper.value = swiper
}

function handleScroll(distance) {
  nextTick(() => {
    if (distance > 0) {
      refSwiper.value?.slideNext()
    } else {
      refSwiper.value?.slidePrev()
    }
  });
}

function handleTypeClick(type) {
  /// 清掉原本條件
  searchQueryStore.initQueryDate()
  /// 設定類型
  searchQueryStore.setSelectedCategoryObject({
    [typeCategoryId]: [type.id]
  });
  /// 搜尋
  handleSearchWithStore()
  /// go
  go('/search-result')
}
</script>

<style scoped lang="scss">
:deep(.swiper-wrapper) {
  @apply flex;
  > div:first-child {
    @apply mobile:ml-[16px];
  }
}

:deep(.swiper-pagination-bullet-active) {
  @apply bg-osn-yellow-00;
}

:deep(.swiper-slide) {
  @apply w-fit;
}

.title {
  @apply text-[24px] font-bold ml-[16px];
}

.space-type-list-wrap {
  @apply relative w-full flex items-center h-[130px] mx-auto pl-[140px] pr-[165px] max-w-[1366px]
  mobile:px-0 mobile:h-fit;
  .title-wrap {
    @apply min-w-[64px] flex flex-col items-center text-osn-grey-01 text-[16px]
    mobile:hidden;
    svg {
      @apply mb-[12px];
    }
  }

  .divider {
    @apply w-[1px] h-full bg-osn-grey-03 mx-[30px];
  }

  .type-wrap {
    @apply flex overflow-x-auto;

    .type-card-wrap {
      @apply flex flex-col items-center w-[130px] min-w-[130px] h-[130px] rounded-[20px] bg-[#FFEFC6] pt-[30px] cursor-pointer
      mobile:w-[96px] mobile:min-w-[96px] mobile:h-[96px] mobile:pt-[20px];
      span {
        @apply h-fit text-[16px] text-[#000] mx-[15px] text-center mt-[4px] mb-[16px]
        mobile:text-[14px] mobile:mb-0 mobile:mx-[8px];
      }
    }
  }

  .space-type-prev {
    @apply absolute top-[40px] left-[240px] cursor-pointer z-20
    mobile:hidden;
  }

  .space-type-next {
    @apply absolute top-[40px] right-[140px] cursor-pointer z-20
    mobile:hidden;
  }
}
</style>
