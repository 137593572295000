/*
1. 攝影棚
2. 忠孝復興捷運站附近，固定2組
3. 行天宮捷運站附近，固定2組
* */

const newSpaceListQueryData = {
    "pagination": {
        "dir": 1,
        "limit": 20,
        "page": 1,
        "sort": "created_date"
    }
}

const photoStudio={
    "pagination": {
        "dir": 0,
        "limit": 4,
        "page": 1,
        "sort": "created_date"
    },
    filter_options: {
        category_ids: {
            '2':[13]
        }
    }
}
const zhongxiaoFuxingStation1={
    "pagination": {
        "dir": 0,
        "limit": 4,
        "page": 1,
        "sort": "location"
    },
    filter_options: {
        "geo_search": {
            "longitude": 121.5394226856529,
            "latitude": 25.04064821708269,
            "width": 4000,
            "height": 4000
        }
    }
}

const hsingTianTempleStation1={
    "pagination": {
        "dir": 0,
        "limit": 4,
        "page": 1,
        "sort": "location"
    },
    filter_options: {
        "geo_search": {
            "longitude": 121.53321241516821,
            "latitude": 25.060117562272325,
            "width": 4000,
            "height": 4000
        }
    }
}

const trendSpaceListQueryDataList = [
    photoStudio,
    zhongxiaoFuxingStation1,
    hsingTianTempleStation1
]

export { newSpaceListQueryData,trendSpaceListQueryDataList };
